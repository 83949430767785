import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import { userSessionAtom, userPoolAtom, cognitoUserAtom } from "../store";

const HeaderIdButtons = () => {
    const [userSession, setUserSession] = useAtom(userSessionAtom);
    const [userPool, setUserPool] = useAtom(userPoolAtom);
    const [cognitoUser, setCognitoUser] = useAtom(cognitoUserAtom);

    const signOut = (e) => {
        e.preventDefault();
        cognitoUser.signOut();
        const blankUser = new CognitoUser({Username: "", Pool: userPool});
        setCognitoUser(blankUser);
        setUserSession(null);
    }

    return(
        <div className="identity-button-group">
            {userSession
                ?
                    <a href="#" className="identity-button" onClick={signOut}>signout</a>
                :
                    <div>
                    <Link to="/signin?action=signin" className="identity-button" >signin</Link>
                    <Link to="/signin?action=signup" className="identity-button" >signup</Link>
                    </div>
        }
        </div>
    )
}

export default HeaderIdButtons;