import { Link } from "react-router-dom";

const AppTitle = () => {
    return(
        <Link to="/" className="App-title">
            comicsgoat
        </Link>
    )
}

export default AppTitle;