import { useEffect } from 'react';
import * as AWS from 'aws-sdk/global';
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Shake } from 'reshake';
import { useAtom } from 'jotai';

import { ReactComponent as Logo } from '../logo.svg';

import { userPoolAtom, userSessionAtom, cognitoUserAtom } from '../store';


const SignInForm = () => {
    const [userPool, ] = useAtom(userPoolAtom);
    const [userSession, setUserSession] = useAtom(userSessionAtom);
    const [cognitoUser, setCognitoUser] = useAtom(cognitoUserAtom);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [shake, setShake] = useState(false);
    const [validCredentials, setValidCredentials] = useState(true);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            setShake(false);
        }, 200);

        return () => clearTimeout(delayDebounce);
    }, [shake]);

    const navigate = useNavigate();

    const updateUsername = (e) => {
        e.preventDefault();
        setUsername(e.target.value);
    };

    const updatePassword = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
    };

    const submitForm = (e) => {
        e.preventDefault();

        let authenticationData = {
            Username: username,
            Password: password,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: username,
            Pool: userPool 
        }
        
        const newCognitoUser = new CognitoUser(userData);

        newCognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                localStorage.setItem("userSession", JSON.stringify(result));
                setUserSession(result);
                setCognitoUser(newCognitoUser);

                setValidCredentials(true);
                
                navigate('/');
            },
            onFailure: (err) => {
                setShake(true);
                setValidCredentials(false);
            }
        });

    };

    return(
        <Shake className="absolute-centered" v="0" r="0" fixed="true" active={shake}>
            <div className="signin-frame">
                <Link className="identity-logo-frame" to="/"> 
                    <Logo className="identity-page-logo"/>
                    comicsgoat
                </Link>
                <div className="signin-form">
                    <div className="identity-form-header">
                        Sign In
                    </div>
                    <form>
                        <span className="invalid-input-message" style={validCredentials ? {visibility: "hidden"} : {visibility: "visible"}}>
                            Invalid Credentials
                        </span>
                        <label>
                            <span className="form-label">Username</span>
                            <input onChange={updateUsername} className="id-field" type="text" name="username" />
                        </label>
                        <label>
                            <span className="form-label">Password</span>
                            <input onChange={updatePassword} className="id-field" type="password" name="password" />
                        </label>
                        <button onClick={submitForm} type="submit">Submit</button>
                    </form>
                </div>
                <div className="id-link-group">
                    <Link className="identity-link" to="/signin?action=signup">Sign Up</Link>
                    <Link className="identity-link" to="/forgot-password">Forgot Password?</Link>
                </div>
            </div>
        </Shake>
    )
}

export default SignInForm;