import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { atom } from "jotai";

// for cognito connection; shouldn't be updated
const poolData = {ClientId: "1tm8kg162619uhsrt0v2rc694n", UserPoolId: "us-west-2_dgiUHKM9U",};
const userPool = new CognitoUserPool(poolData);
export const userPoolAtom = atom(userPool);

// user session data

// blank cognito user
const blankUser = new CognitoUser({Username: "", Pool: userPool});
export const cognitoUserAtom = atom(blankUser);

export const userSessionAtom = atom(null);
