import { useMemo, useEffect } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom"
import { useAtom } from "jotai";
import { CognitoUser } from "amazon-cognito-identity-js";

import { userPoolAtom, userSessionAtom, cognitoUserAtom } from "../store";

import SignInForm from "../components/signin-form";
import SignUpForm from "../components/signup-form";
import VerifyAccountForm from '../components/verify-account-form';
import AppTitle from "../components/app-title";

export default function SignIn() {
    
    const [userSession, setUserSession] = useAtom(userSessionAtom);
    const [userPool, setUserPool] = useAtom(userPoolAtom);
    const [cognitoUser, setCognitoUser] = useAtom(cognitoUserAtom);

    const location = useLocation();
    const navigate = useNavigate();

    // get user session from local storage and navigate to home if user is logged in
    useEffect(() => {
        let currentUser = userPool.getCurrentUser();
        if (currentUser != null) {
            currentUser.getSession((err, result) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }

                // get attributes goes here if i need it
                setUserSession(result);
                setCognitoUser(currentUser);
                if (result.isValid) {
                    navigate('/');
                }
            });
        }
    }, [])

    const useQuery = () => {
        return new URLSearchParams(location.search);
    }

    let query = useQuery().get("action");
    
    let actionComponent;
    switch(query) {
        case "verify":
            actionComponent = <VerifyAccountForm  />
            break;
        case "signup":
            actionComponent = <SignUpForm />
            break;
        case "signin":
            actionComponent = <SignInForm />
            break;
        default:
            actionComponent = <SignInForm />
            break;
    }

    return (
        <div className="signin-page">
            <div className="App-header">
                <AppTitle />
            </div>
            {actionComponent}
        </div>
    )
}