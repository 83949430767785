import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Shake } from 'reshake';
import { Link, useNavigate } from 'react-router-dom';
import { userPoolAtom, cognitoUserAtom, userSessionAtom } from "../store";
import { ReactComponent as Logo } from '../logo.svg';

export default function VerifyAccountForm() {
    const [userSession, setUserSession] = useAtom(userSessionAtom);
    const [cognitoUser, setCognitoUser] = useAtom(cognitoUserAtom);
    const [userPool, setUserPool] = useAtom(userPoolAtom);

    const [showResentMessage, setShowResendMessage] = useState(false);
    const [verificationResentMessage, setVerificationResentMessage] = useState("Verification code resent");
    const [shake, setShake] = useState(false);
    const [verificationInput, setVerificationInput] = useState("");

    const navigate = useNavigate();
    
    // get user session from local storage
    useEffect(() => {
        let currentUser = userPool.getCurrentUser();
        if (currentUser != null) {
            currentUser.getSession((err, result) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }

                // get attributes goes here if i need it
                setUserSession(result);
                setCognitoUser(currentUser);
                if (result.isValid) {
                    navigate('/');
                }
            });
        }
    }, [])

    // turn off the shake effect
    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            setShake(false);
        }, 200);

        return () => clearTimeout(delayDebounce);
    }, [shake]);

    const verificationInputChange = (e) => {
        e.preventDefault();
        setVerificationInput(e.target.value);
    }

    const submitVerificationForm = (e) => {
        e.preventDefault();
        console.log(cognitoUser.getUsername())
        cognitoUser.confirmRegistration(verificationInput, true, (err, result) => {
            if (err) {
                console.log(err);
                setShake(true);
            }
            console.log(result);
            // TODO: log user in
            navigate("/");
        })
    }

    const resendVerificationCode = (e) => {
        e.preventDefault();
        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                setVerificationResentMessage("Error resending verification code");
                setShowResendMessage(true);
                console.log(err);
            } else {
                setVerificationResentMessage("Verification code emailed to " + result.CodeDeliveryDetails.Destination);
                setShowResendMessage(true);
                console.log(result);
            }
        })
    }

    return(
        <Shake v="0" r="0" fixed="true" active={shake}>
            <div className="signin-frame verify-account-frame">
                <Link className="identity-logo-frame" to="/"> 
                    <Logo className="identity-page-logo"/>
                    comicsgoat
                </Link>
                <form>
                    <label>
                        <span style={showResentMessage ? {visibility: "visible"} : {visibility: "hidden"}}>{verificationResentMessage}</span>
                        <input type="text" className="id-field" onChange={verificationInputChange} placeholder="Enter verification code" />
                    </label>
                    <a href="#" className="resend-verification" onClick={resendVerificationCode}>Resend Verification Code</a>
                    <button className="" onClick={submitVerificationForm} type="submit">Submit</button>
                </form>
            </div>
        </Shake>
    )

}