import { Provider, useAtom, atom } from "jotai";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';

import { cognitoUserAtom, userSessionAtom, userPoolAtom } from "../store";

import AppTitle from "../components/app-title";
import HeaderIdButtons from "../components/header-id-buttons";


const Landing = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [userSession, setUserSession] = useAtom(userSessionAtom);
    const [cognitoUser, setCognitoUser] = useAtom(cognitoUserAtom);
    const [userPool, setUserPool] = useAtom(userPoolAtom);


    useEffect(() => {
        let currentUser = userPool.getCurrentUser();
        if (currentUser != null) {
            currentUser.getSession((err, result) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }

                // get attributes goes here if i need it

                setUserSession(result);
                setCognitoUser(currentUser);
            });
        }
    }, [])

    return (
        <div className="landing-page">
            <div className="App-header">
                <AppTitle />
                <div className="App-notification-group">
                </div>
                <HeaderIdButtons />
            </div>
            <div className="App-body">
                <div className="flex-col">
                    {
                        userSession 
                            ? userSession.isValid()
                                ? "Logged In" 
                                : "Not Logged In"
                            : "Not Logged In"
                    }
                    <br />
                    {
                        userPool
                            ? "User Pool Exists"
                            : "User Pool Does Not Exist"
                    }
                    <br />
                    {
                        cognitoUser
                            ? "Cognito User Exists"
                            : "Cognito User Does Not Exist"
                    }
                    <br />
                    {
                        cognitoUser.getUsername()
                            ? "Username: " + cognitoUser.getUsername()
                            : "No Username"
                    }
                </div>
            </div>
        </div>
    );
}

export default Landing;