import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider, atom } from 'jotai'; 
import Landing from './routes/landing-page';
import SignIn from './routes/signin-page';
import VerifyPage from './routes/verify-account-page';
import "./App.css";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider>
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Landing />} />
        <Route path="/signin" exact element={<SignIn /> } />
      </Routes>
    </BrowserRouter>
  </Provider>
);
