import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import validator from 'validator';
import { userPoolAtom, cognitoUserAtom, userSessionAtom } from "../store";

const SignUpForm = () => {
    const [userSession, setUserSession] = useAtom(userSessionAtom);
    const [cognitoUser, setCognitoUser] = useAtom(cognitoUserAtom);
    const [userPool, setUserPool] = useAtom(userPoolAtom);

    const [password, setPassword] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [email, setEmail] = useState("");
    const [emailValid, setEmailValid] = useState(true);
    const [username, setUsername] = useState("");
    const [usernameAvailable, setUsernameAvailable] = useState(true);

    // get user session from local storage
    useEffect(() => {
        let currentUser = userPool.getCurrentUser();
        if (currentUser != null) {
            currentUser.getSession((err, result) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }

                // get attributes goes here if i need it
                setUserSession(result);
                setCognitoUser(currentUser);
                if (result.isValid) {
                    navigate('/');
                }
            });
        }
    }, [])

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            setEmailValid(validator.isEmail(email) || email === "");
        }, 400);

        return () => clearTimeout(delayDebounce);
    }, [email]);

    useEffect(() => {
        const delayDebounce = setTimeout(() => {
            let r = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$");
            setPasswordValid(r.test(password) || password === "");
        }, 400);

        return () => clearTimeout(delayDebounce);
    }, [password])

    const navigate = useNavigate();

    const updateEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        // check for valid email format
    }

    const updateUsername = (e) => {
        e.preventDefault()
        setUsername(e.target.value);
        // check if username is taken
    }

    const updatePassword = (e) => {
        e.preventDefault();
        setPassword(e.target.value);
        setPasswordsMatch(confirmPassword === e.target.value || confirmPassword === "");
    }

    const updateConfirmPassword = (e) => {
        e.preventDefault();
        setConfirmPassword(e.target.value);
        setPasswordsMatch(password === e.target.value || e.target.value === "");
    }

    const submitForm = (e) => {
        e.preventDefault();
        if(passwordValid && passwordsMatch && emailValid && usernameAvailable) {
            if(password !== "" && confirmPassword !== "" && email !== "" && username !== "") {
                let attributeList = [];
                let dataEmail = {
                    Name: 'email',
                    Value: email
                };
                let attributeEmail = new CognitoUserAttribute(dataEmail);
                attributeList.push(attributeEmail);

                userPool.signUp(username, password, attributeList, null, (err, result) => {
                    if(err) {
                        console.log(err);
                        return;
                    }
                    setCognitoUser(result.user);
                    setUserSession(result.userSession);
                    navigate('/signin?action=verify')
                })
            }
        }
        // do cognito stuff
            // check if email is taken
            // check if username is taken
    }

    
    return(
        <div className="signin-frame">
            <Link className="identity-brand-frame" style={{fontSize: '3em', marginTop: '2.5%', color: '#48B68D'}}to="/" >
                comicsgoat
            </Link>
            <div className="identity-form-header">
                Sign Up
            </div>
            <div className="signin-form">
                <form>
                    <label>
                        <span className="form-label">Email Address</span>
                        <input style={ emailValid ? {} : { boxShadow: "0 0 0 2px #C2573F" } } onChange={ updateEmail } className="id-field" type="text" name="email" />
                        <span style={ emailValid ? { visibility: "hidden" } : { visibility: "visible" } } className="invalid-span">Enter A Valid Email Address</span>
                    </label> 
                    <label>
                        <span className="form-label">Username</span>
                        <input style={ usernameAvailable ? {} : { boxShadow: "0 0 0 2px #C2573F" } } onChange={ updateUsername } className="id-field" type="text" name="username" />
                        <span style={ usernameAvailable ? { visibility: "hidden" } : { visibility: "visible" } } className="invalid-span">Username unavailable</span>
                    </label>
                    <label>
                        <span className="form-label">Password</span>
                        <input onChange={updatePassword} style={passwordValid ? {} : { boxShadow: "0 0 0 2px #C2573F" } } className="id-field" type="password" name="password" />
                        <span className="invalid-span">
                            Use 8 or more characters using a mix of letters, numbers & symbols
                        </span>
                        <span className="form-label">Confirm Password</span>
                        <input onChange={ updateConfirmPassword } style={ passwordsMatch ? {} : { boxShadow: "0 0 0 2px #C2573F" } } className="id-field" type="password" name="confirmPassword" />
                        <span style={ passwordsMatch ? { visibility: "hidden" } : { visibility: "visible" } } className="invalid-span">Passwords don't match</span>
                    </label>
                    <button onClick={ submitForm } type="submit">Submit</button>
                </form>
            </div>
            <div className="id-link-group">
                <Link className="identity-link" to="/signin">Sign In</Link>
            </div>
        </div>
    )
}

export default SignUpForm;